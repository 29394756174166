import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { intercomUrl, spleisUrl } from '../config';
import withData from '../with-data';
import Comments from '../Comments/Comments';
import Audits from '../Audits/Audits';
import ProfileInfo from '../ProfileInfo/ProfileInfo';
import InfoDataList, { DataListItem } from '../InfoDataList/InfoDataList';
import VisibleIf from '../VisibleIf/VisibleIf';
import { DATE_FORMAT } from '../dates';
import VerificationBadge from '../VerificationBadge/VerificationBadge';
import TaskContainer from '../TaskContainer/TaskContainer';
import TransactionTable from '../TransactionTable/TransactionTable';
import api from '../api';
import { showError, showInfo } from '../store';
import Reports from '../Reports/Reports';
import RefundTable from '../Refund/RefundTable';
import Button from '../Button/Button';
import { ProjectList } from '../Project/ProjectList';
import PhoneNumberLink from './PhoneNumberLink';
import InvoiceTable from '../Invoice/InvoiceTable';
import UserInfo from './UserInfo';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import styles from './User.module.scss';
import SendSmsToUser from '../SendSmsToUser/SendSmsToUser';
import UserProjectStats from './UserProjectStats';
import { Helmet } from 'react-helmet';
import RedMark from './RedMark/RedMark';
import AddRedMark from './RedMark/AddRedMark';
import { convertDateAsStringWithTwoDigitYearToFourDigitYear } from '../formatters';
import Banner from '../Banner/Banner';

export const USER_TYPE = { ORG: 'org', USER: 'user' };

const UserProfile = ({
    user,
    bankAccount,
    organizations,
    deleteUser,
    deleteVerifiedInfo,
    login,
    verifyIsOwnerOfBankAccount,
    deleteFailedLoginAttempts,
    yellowMark,
    redMark,
    addRedMark,
    removeRedMark,
}) => {
    const [invoices, setInvoices] = useState([]);
    const [creditedInvoices, setCreditedInvoices] = useState([]);

    const flipName = (reversedName = '') => reversedName?.split(',')?.reverse()?.join(' ')?.trim();

    const {
        id,
        first,
        last,
        email,
        img_url,
        phone_number,
        vipps_number,
        created_at,
        facebook_id,
        google_id,
        vipps_id,
        activated,
        hasPassword,
        activation_code,
        last_login_attempt,
        failed_login_attempts,
        location,
        phone_number_verified_at,
        verification_data,
        otherUserProfiles,
    } = user;

    useEffect(() => {
        if (id) {
            api.get(`${spleisUrl}/api/admin/invoice/user/${id}`)
                .then((res) => {
                    setInvoices(res.filter((invoice) => !invoice.creditnote_at));
                    setCreditedInvoices(res.filter((invoice) => invoice.creditnote_at));
                })
                .catch(() => showError('Feil ved henting av fakturaer'));
        }
    }, [id]);

    const otherProfileIds = otherUserProfiles?.filter((id) => parseInt(id, 10) !== parseInt(user.id, 10));
    const hasVerificationData = !!(verification_data && verification_data.verifiedInformation);

    const accountOwnershipVerification = bankAccount?.kar_account_verification
        ? bankAccount.kar_account_verification.description
        : 'Ikke sjekket';

    let name;
    if (first && last) {
        name = `${first} ${last}`;
    }
    const verifiedName = flipName(verification_data?.verifiedInformation?.name);

    const hasLogin = hasPassword || facebook_id || google_id || vipps_id;

    const verificationHistory = user?.verification_data?.verificationAttempts?.map((attempt) => (
        <li className={styles.verificationListItem}>
            <span>{attempt.name}: </span>
            <span className={styles.verificationDate}>{moment(attempt.created_at).format(DATE_FORMAT)}</span>
        </li>
    ));

    const verifiedInformation = verification_data?.verifiedInformation;

    let userBirthDate;

    if (verifiedInformation?.fnr) {
        userBirthDate = moment(
            convertDateAsStringWithTwoDigitYearToFourDigitYear(verifiedInformation?.fnr?.slice(0, 6)),
            'DDMMYYYYY'
        );
    } else if (verifiedInformation?.partially_verified_at && verifiedInformation?.birthDate) {
        userBirthDate = moment(verifiedInformation?.birthDate);
    }

    let formattedUserBirthDate;
    if (userBirthDate) {
        formattedUserBirthDate = `${userBirthDate.format('DD.MM.YYYY')} (${moment().diff(userBirthDate, 'years')} år)`;
    }

    return (
        <div>
            <Helmet>
                <title>{verifiedName || name || 'Ikke-registrert bruker'}</title>
            </Helmet>
            <header className={`task-header ${!name && !hasVerificationData ? 'faded' : null}`}>
                <ProfileInfo
                    userId={parseInt(id, 0)}
                    className={[styles.userContainer, 'task-body'].join(' ')}
                    size="large"
                    headerTitle={`Innsamler #${id}`}
                    titlePlaceholder={'Ikke-registrert bruker'}
                    title={name}
                    verified={!!(hasVerificationData && verifiedInformation.verified_at)}
                    partiallyVerified={!!(hasVerificationData && verifiedInformation.partially_verified_at)}
                    identification={hasVerificationData ? `Verifisert navn: ${verifiedName}` : null}
                    imageSrc={img_url}
                />
            </header>

            <div className="task-actions">
                {hasLogin && (
                    <>
                        <Button title={'Logg inn som denne brukeren'} className={'task-action-button'} onClick={login}>
                            Logg inn
                        </Button>
                        <Button
                            title="Sjekk konto i KAR"
                            className="task-action-button"
                            onClick={verifyIsOwnerOfBankAccount}
                        >
                            Sjekk KAR-registeret
                        </Button>
                    </>
                )}
                <a
                    title="Send melding"
                    className="btn task-action-button"
                    href={`${intercomUrl}/users/show?email=${email}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Send melding
                </a>
                <Button title="Slett bruker" className="task-action-button" onClick={() => deleteUser(user.id)}>
                    Slett bruker
                </Button>
                {verifiedInformation && (
                    <Button
                        title="Slett verifisert info"
                        className="task-action-button"
                        onClick={() => deleteVerifiedInfo(user.id)}
                    >
                        Slett verifisert info
                    </Button>
                )}
                {user?.failed_login_attempts > 0 && (
                    <Button
                        title="Nullstill innloggingsforsøk"
                        className="task-action-button"
                        onClick={() => deleteFailedLoginAttempts(user.id)}
                    >
                        Nullstill innloggingsforsøk ({user?.failed_login_attempts})
                    </Button>
                )}
                {user?.phone_number && <SendSmsToUser user={user} />}
                {!redMark && <AddRedMark addRedMark={addRedMark} />}
            </div>
            <div className="task-body">
                {otherProfileIds?.length > 0 && (
                    <div>
                        <h3>Brukere med samme personnummer:</h3>
                        <div className={styles.userList}>
                            {otherProfileIds.map((id) =>
                                id === user.id ? null : (
                                    <Link to={`/user/${id}`} className={styles.userLink}>
                                        <UserInfo id={id} />
                                    </Link>
                                )
                            )}
                        </div>
                    </div>
                )}

                <RedMark redMark={redMark} onRemove={removeRedMark} showRedMarkInfo />
                {yellowMark && <Banner title={yellowMark} themeClass="warning" />}

                <InfoDataList>
                    <DataListItem label="Oppgitt navn">{name}</DataListItem>
                    <DataListItem label="BankID navn">{verifiedName}</DataListItem>
                    <DataListItem>{email}</DataListItem>
                    <DataListItem label="Telefon">
                        <PhoneNumberLink phoneNumber={phone_number} />
                        <VisibleIf isVisible={!!phone_number}>
                            <VerificationBadge verified={!!phone_number_verified_at} />
                        </VisibleIf>
                    </DataListItem>
                    <VisibleIf isVisible={phone_number !== vipps_number}>
                        <DataListItem label="Vipps-nummer">
                            <PhoneNumberLink phoneNumber={vipps_number} />
                        </DataListItem>
                    </VisibleIf>
                    <DataListItem label="Fødselsdato">{formattedUserBirthDate}</DataListItem>
                </InfoDataList>

                {(facebook_id || hasPassword || google_id || vipps_id) && (
                    <div className={styles.loginInformation}>
                        <h3 className={styles.loginInformationTitle}>Brukeren logger inn med</h3>
                        <div className={styles.loginOptions}>
                            {vipps_id && <span className={styles.loginOption}>Vipps</span>}
                            {facebook_id && <span className={styles.loginOption}>Facebook</span>}
                            {google_id && <span className={styles.loginOption}>Google</span>}
                            {hasPassword && <span className={styles.loginOption}>E-post</span>}
                        </div>
                    </div>
                )}

                <VisibleIf isVisible={organizations && organizations.length > 0}>
                    <InfoDataList title="Kontaktperson for:">
                        {organizations.map((organization) => (
                            <DataListItem>
                                <a href={`/organizations/${organization.id}`}>
                                    {organization.name}{' '}
                                    {organization.verified_at ? '(Verifisert)' : '(Ikke verifisert)'}
                                </a>
                            </DataListItem>
                        ))}
                    </InfoDataList>
                </VisibleIf>
                <InfoDataList title="Kontoinformasjon">
                    <DataListItem label="Kontonummer">{bankAccount?.account_number}</DataListItem>
                    <DataListItem label="Verifisert">
                        {verifiedInformation?.verified_at && (
                            <span>{moment(verifiedInformation.verified_at).format(DATE_FORMAT)}</span>
                        )}
                    </DataListItem>
                    <DataListItem label="Har personnummer?">{verifiedInformation?.fnr ? 'Ja' : 'Nei'}</DataListItem>
                    <DataListItem label="KAR-registeret">{accountOwnershipVerification}</DataListItem>
                    {bankAccount?.verification_data?.last_attempt_message && (
                        <DataListItem label="Info">{bankAccount?.verification_data?.last_attempt_message}</DataListItem>
                    )}
                </InfoDataList>
                {verificationHistory && (
                    <InfoDataList title="Verifiseringshistorikk">
                        <DataListItem>
                            <ul className={styles.verificationList}>{verificationHistory}</ul>
                        </DataListItem>
                    </InfoDataList>
                )}
                <InfoDataList title="Innlogging">
                    <DataListItem label="Opprettet">{moment(created_at).format(DATE_FORMAT)}</DataListItem>
                    <DataListItem label="Vipps-id">{vipps_id}</DataListItem>
                    <DataListItem label="Facebook-id">{facebook_id}</DataListItem>
                    <DataListItem label="Google-id">{google_id}</DataListItem>
                    <DataListItem label="Forrige innloggingsforsøk">
                        {last_login_attempt && moment(last_login_attempt).format(DATE_FORMAT)}
                    </DataListItem>
                    <DataListItem label="Mislykkede">{failed_login_attempts}</DataListItem>
                    <DataListItem label="Aktivert">{activated ? 'Ja' : 'Nei'}</DataListItem>
                    <DataListItem label="Aktiviseringskode">{activation_code}</DataListItem>
                </InfoDataList>
                {location ? (
                    <InfoDataList title="Lokasjon">
                        <DataListItem label="Postnr/sted">
                            {location.postal_code} {location.postal_name}
                        </DataListItem>
                        <DataListItem label="Kommune">{location.municipality_name}</DataListItem>
                        <DataListItem label="Fylke">{location.county_name}</DataListItem>
                    </InfoDataList>
                ) : (
                    ''
                )}
                <UserTransactionsWithData id={id} showProjectId={true} showCommentCheckbox />
                <InvoiceTable title="Fakturaer" data={invoices} />
                <UserRefundsWithData id={id} />
                <InvoiceTable title="Krediterte fakturaer" data={creditedInvoices} />
            </div>
        </div>
    );
};

const UserProjectStatsWithData = withData(UserProjectStats, (id) => `${spleisUrl}/api/admin/user/${id}/projectStats`);

const UserProjectsWithData = withData(ProjectList, (id) => `${spleisUrl}/api/admin/project/user/${id}`);

const UserTransactionsWithData = withData(TransactionTable, (id) => `${spleisUrl}/api/admin/transaction/user/${id}`);

const UserRefundsWithData = withData(RefundTable, (id) => `${spleisUrl}/api/admin/transaction/refund/user/${id}`);

const UserDetail = (props) => {
    const user = props.user;

    if (!user.id) {
        return <div />;
    }

    return (
        <div>
            <Comments lookupKey="user" id={user.id} postToSlack={false} />
            <Reports id={user.id} user={user} />
            <div>
                <header>
                    <h2>Prosjekter:</h2>
                </header>
                <UserProjectStatsWithData id={props.user.id} />
                <UserProjectsWithData id={props.user.id} />
            </div>
            <Audits lookupKey="userId" id={user.id} />
        </div>
    );
};

function filterInt(value) {
    if (/^[-+]?(\d+|Infinity)$/.test(value)) {
        return Number(value);
    } else {
        return NaN;
    }
}

const User = () => {
    const params = useParams();
    const [user, setUser] = useState(null);
    const [bankAccount, setBankAccount] = useState(null);
    const [organizations, setOrganizations] = useState(null);
    const [canBeDeleted, setCanBeDeleted] = useState(null);
    const [reasonsWhyUserCannotBeDeleted, setReasonsWhyUserCannotBeDeleted] = useState(null);
    const [redMark, setRedMark] = useState(null);
    const [yellowMark, setYellowMark] = useState(null);

    useEffect(() => {
        if (filterInt(params.userId)) {
            fetch(params.userId);
        }
    }, [params.userId]);

    const fetch = (userId) => {
        fetchUser(userId);
        fetchCanUserBeDeleted(userId);
        fetchAccount(userId);
        fetchOrganizations(userId);
        fetchRedMark(userId);
        fetchYellowMark(userId);
    };

    const fetchUser = (id) => {
        if (id) {
            api.get(`${spleisUrl}/api/admin/user/${id}`)
                .then((res) => setUser(res))
                .catch((err) => showError(err));
        }
    };

    const login = () => {
        const spleis = window.open();
        api.post(`${spleisUrl}/api/admin/user/${user.id}/prepareLogin`)
            .then((res) => {
                const token = res.token;
                spleis.location.href = `${spleisUrl}/api/public/user/${user.id}/login?token=${token}`;
            })
            .catch((e) => showError('Feil ved innlogging: ' + e));
    };

    const fetchAccount = (userId) => {
        if (userId) {
            api.get(`${spleisUrl}/api/admin/bank-account/main-account-for-user/${userId}`)
                .then((res) => setBankAccount(res))
                .catch((err) => showError(err));
        }
    };

    const fetchOrganizations = (userId) => {
        if (userId) {
            api.get(`${spleisUrl}/api/admin/organization/organizations-for-user/${userId}`)
                .then((res) => setOrganizations(res))
                .catch((err) => showError(err));
        }
    };

    const verifyIsOwnerOfBankAccount = () => {
        api.post(`${spleisUrl}/api/admin/bank-account/${bankAccount.id}/verify-ownership`)
            .then((res) => {
                showInfo('Sjekk mot KAR-registeret gjennomført');
                setBankAccount(res);
            })
            .catch((error) => {
                showError(error);
            });
    };

    const fetchCanUserBeDeleted = (id) => {
        api.get(`${spleisUrl}/api/admin/user/${id}/canBeDeleted`)
            .then((res) => {
                setCanBeDeleted(res.canBeDeleted);
                setReasonsWhyUserCannotBeDeleted(res.reasonsWhyUserCannotBeDeleted);
            })
            .catch((error) => {
                showError(error);
            });
    };

    const deleteUser = (id) => {
        if (canBeDeleted) {
            if (window.confirm('Er du sikker på at du vil slette brukeren?')) {
                api.remove(`${spleisUrl}/api/admin/user/${id}`)
                    .then(() => {
                        showInfo('Bruker slettet');
                        fetch(id);
                    })
                    .catch((error) => {
                        showError(error);
                    });
            }
        } else {
            window.alert(`Brukeren kan ikke slettes fordi:\n\n${reasonsWhyUserCannotBeDeleted.join('\n')}`);
        }
    };

    const deleteVerifiedInfo = (id) => {
        if (window.confirm('Er du sikker på at du vil slette brukerens verifiserte informasjon?')) {
            api.remove(`${spleisUrl}/api/admin/user/${id}/verified-information`)
                .then(() => {
                    showInfo('Brukerens verifiserte informasjon slettet');
                    fetch(id);
                })
                .catch((error) => {
                    showError(error);
                });
        }
    };

    const deleteFailedLoginAttempts = (id) => {
        api.remove(`${spleisUrl}/api/admin/user/${id}/failed-login-attempts`)
            .then(() => {
                showInfo('Nullstilte innlogginsforsøk');
                fetch(id);
            })
            .catch((error) => {
                showError(error);
            });
    };

    const fetchYellowMark = (userId) => {
        api.get(`${spleisUrl}/api/admin/user/${userId}/tagged-project-ids?tag=kundekontroll`)
            .then((res) => {
                if (res.projectIds?.length > 0) {
                    setYellowMark('Kundekontroll')
                } else {
                    setYellowMark(null);
                }
            })
            .catch((err) => showError(err));
    };

    const fetchRedMark = (userId) => {
        api.get(`${spleisUrl}/api/admin/user/${userId}/red-mark`)
            .then((res) => setRedMark(res.redMark))
            .catch((err) => showError(err));
    };

    const addRedMark = (reason) => {
        api.post(`${spleisUrl}/api/admin/user/${user?.id}/red-mark`, {
            reason,
        })
            .then(() => {
                showInfo('Bruker rødmerket.');
                fetch(user?.id);
            })
            .catch(() => showError('Noe gikk feil ved rødmerking', 3));
    };

    const removeRedMark = () => {
        api.remove(`${spleisUrl}/api/admin/user/${user?.id}/red-mark`)
            .then(() => {
                showInfo('Rødmerking fjernet.');
                fetch(user?.id);
            })
            .catch(() => showError('Noe gikk feil ved fjerning av rødmerking', 3));
    };

    const mappedUser = Object.assign({}, user, {
        hasPassword: !!user?.password_hash,
        password_hash: 'skjult',
        salt: 'skjult',
    });

    return (
        <TaskContainer detail={<UserDetail user={mappedUser} />}>
            <UserProfile
                user={mappedUser}
                bankAccount={bankAccount}
                organizations={organizations || []}
                deleteUser={deleteUser}
                deleteVerifiedInfo={deleteVerifiedInfo}
                deleteFailedLoginAttempts={deleteFailedLoginAttempts}
                verifyIsOwnerOfBankAccount={verifyIsOwnerOfBankAccount}
                yellowMark={yellowMark}
                redMark={redMark}
                addRedMark={addRedMark}
                removeRedMark={removeRedMark}
                login={login}
            />
        </TaskContainer>
    );
};

export default User;
